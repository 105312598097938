//@flow

import type { ValidationRules } from "./Validation";
import type { ErrorResult } from "./Validation";
import type { LangString } from './lib/Language';
export type WebSettings={
	uploadLimit: number;
};

export type Option={
	category?: string;
	value: string;
	label: string;
	description?: string;
};

export type LangOption={
	category?: LangString;
	value: string;
	label: LangString;
	description?: LangString;
	order?: number|null;
};

export type DbFileInfo={
	mimeType: string;
	name: string;
	size: number;
	added: string;
};

export type ImportType = 'CSV'|'XLSX';
export type ImportResult={
	type: ImportType;
	sep: string;
	quote: string;
	columns: { [string]: number|null };
	missingColumns: Array<string>;
	data: Array<{}>;
};

export type OrderStatus = 'Grace'|'Ordered'|'InProgress'|'Rejected'|'Done';
export type VindicationTableInfo={
	initialCapital: string;
	initialInterests: string;
	initialCosts: string;
	actualCapital: string;
	actualInterests: string;
	actualCosts: string;
	recoveredCapital: string;
	recoveredInterests: string;
	recoveredCosts: string;
	id: string;
	fullId: string;
	added: string;
	ecId: string;
	tax: string;
	debtor: string;
	extId: string;
	currency: string;
	changed: string;
	stageChanged: string;
	status: OrderStatus;
	stage: string;
	ecStatus: string|null;
	amicable: boolean;
	judicial: boolean;
	domestic: boolean;
	chargeInterests: boolean;
	chargeCosts: boolean;
	invoiceMonitoring: boolean;
	invoiceMonitoringAutomatic: boolean;
	creditor?: string;
	/** Ext */
	client?: string;
	/** Ext */
	clientEcId?: string|null;
	/** Ext */
	clientFistName?: string;
	/** Ext */
	clientLastName?: string;
	/** Ext */
	clientTax?: string;
	/** Ext */
	rejectionReason?: LangString;
	/** Ext */
	acceptedBy?: string;
	/** Ext */
	worker?: string;
	/** Ext */
	changed2?: string;
	initial: string;
	actual: string;
	recovered: string;
};

export type TableResult<T> = {
	items: number;
	data: Array<T>;
};

export type SystemRight = 'Admin'|'SystemUserRead'|'SystemUserWrite'|'ConfigRead'|'ConfigWrite'|'RemoteHelp'|'DifficultDebtsRead'|'DifficultDebtsWrite'|'PagesRead'|'PagesEdit'|'TechnicalMessage'|'BannerRead'|'BannerEdit'|'EmailTemplatesRead'|'EmailTemplatesWrite'|'LangKeysRead'|'LangKeysWrite'|'AccessLevelRead'|'AccessLevelWrite'|'RejectionReasonRead'|'RejectionReasonWrite'|'ServicesRead'|'ServicesWrite'|'PacketsRead'|'PacketsWrite'|'OrganizationsRead'|'OrganizationsWrite'|'OrganizationsExport'|'UsersRead'|'UsersWrite'|'UsersExport'|'VindicationsRead'|'VindicationsWrite'|'VindicationsExports'|'OrdersRead'|'OrdersWrite'|'VindicationStageNames'|'VindicationGlobalPrices'|'OrganizationOrigin'|'MonitoringEditNotification'|'MonitoringEditScenario'|'MonitoringRead'|'MonitoringEdit'|'Reports';
export type InternalServices = 'Vindication'|'GlobalVindication'|'Monitoring'|'DifficultDebts'|'DebtExchange'|'InvoiceMonitoring';
export type OrderTypeInfo={
	id: string;
	ident: string;
	type: InternalServices;
	name: LangString;
	dbFile?: string;
	custom?: boolean|null;
	imported?: boolean|null;
};

export type AdminSelfInfo={
	id: string;
	name: string;
	email: string;
	rights: Array<SystemRight>;
	orderTypes: Array<OrderTypeInfo>;
};

export type StaticPage={
	title: string;
	content: string;
};

export interface SysPublicAPI {
	changePassword(code: string, newPassword: string): Promise<void|ErrorResult>;
	check(): Promise<boolean>;
	getSelf(): Promise<AdminSelfInfo>;
	getStaticPage(page: string): Promise<StaticPage>;
	isValidCode(code: string): Promise<boolean>;
	login(username: string, password: string): Promise<boolean|ErrorResult>;
	resetPassword(email: string): Promise<string|ErrorResult>;
}

export type SysPublicAPIQuery = {
	changePassword: void|ErrorResult,
	check: boolean,
	getSelf: AdminSelfInfo,
	getStaticPage: StaticPage,
	isValidCode: boolean,
	login: boolean|ErrorResult,
	resetPassword: string|ErrorResult,
};

export type DifficultDebtFile={
	dbId: string;
	customName: string;
};

export type OrganizationStatus = 'Rejected'|'New'|'Active'|'TemporaryBlocked'|'Blocked';
export type UOStatus = 'Rejected'|'New'|'Active'|'TemporaryBlocked'|'Blocked';
export type UserStatus = 'NotAccepted'|'New'|'Active'|'TemporaryBlocked'|'Blocked';
export type ReceivableInfo={
	number: string;
	sell: string;
	pay: string;
};

export type DictionaryType = 'OrganizationOrigin'|'GlobalPrices'|'StageNames';
export type WebDictionaryEntry={
	id: string;
	order: number;
	value: string;
	label: string;
	langLabel: LangString;
};

export type FieldFilter={
	field: string;
	value: any;
};

export type TableQuery={
	sort: string|null;
	items: number;
	from: number;
	filters: Array<FieldFilter>|null;
	query: string|null;
};

export type FileInfo={
	safeId: string;
	name: string;
};

export type SystemUserStatus = 'Active'|'TemporaryBlocked'|'Blocked';
export type AdminUser={
	id: string;
	name: string;
	email: string;
	roles: Array<string>;
	status: SystemUserStatus;
	newPassword: string;
	passwordVisible: boolean;
	repeatPassword: string;
};

export const AdminUserValidation: ValidationRules = {
	id: { },
	name: { notEmpty: true, },
	email: { email: true, notEmpty: true, },
	roles: { },
	status: { notEmpty: true, },
	newPassword: { },
	passwordVisible: { },
	repeatPassword: { },
};

export const AdminUserInitial: AdminUser = {
	id: "",
	name: "",
	email: "",
	roles: [],
	status: "Active",
	newPassword: "",
	passwordVisible: false,
	repeatPassword: "",
};

export type AdminInfo={
	id: string;
	name: string;
	email: string;
	roles: Array<string>;
	lastLogged: string;
	status: SystemUserStatus;
};

export type AgreementInfo={
	id: string;
	name: LangString;
};

export type Language = 'pl'|'en';
export type DifficultDebtInfo={
	id: string;
	lang: Language;
	name: string;
	fileName: string;
	added: string;
	fId: string;
};

export type RejectionReasonInfo={
	id: string;
	message: LangString;
};

export const RejectionReasonInfoValidation: ValidationRules = {
	id: { },
	message: { notEmpty: true, },
};

export const RejectionReasonInfoInitial: RejectionReasonInfo = {
	id: "",
	message: { pl: '', en: '', },
};

export type RoleInfo={
	id: string;
	name: string;
	desc: string;
};

export const RoleInfoValidation: ValidationRules = {
	id: { },
	name: { notEmpty: true, notNull: true, },
	desc: { },
};

export const RoleInfoInitial: RoleInfo = {
	id: "",
	name: "",
	desc: "",
};

export type BannerStatus = 'Active'|'TemporaryBlocked'|'Blocked';
export type BannerFlags = 'OpenInNewWindow';
export type BannerInfo={
	id: string;
	name: LangString;
	from: string;
	to: string;
	status: BannerStatus;
	url: LangString;
	flags: Array<BannerFlags>;
	plFileId: string;
	enFileId: string;
};

export const BannerInfoValidation: ValidationRules = {
	id: { },
	name: { notEmpty: true, },
	from: { notEmpty: true, notNull: true, },
	to: { notEmpty: true, notNull: true, },
	status: { notEmpty: true, notNull: true, },
	url: { notEmpty: true, notNull: true, },
	flags: { },
	plFileId: { notEmpty: true, notNull: true, },
	enFileId: { notEmpty: true, notNull: true, },
};

export const BannerInfoInitial: BannerInfo = {
	id: "",
	name: { pl: '', en: '', },
	from: "",
	to: "",
	status: '',
	url: { pl: '', en: '', },
	flags: [],
	plFileId: "",
	enFileId: "",
};

export type RegisterCompanyInfo={
	name: string;
	postalCode: string;
	city: string;
	address: string;
	country: string;
};

export const RegisterCompanyInfoValidation: ValidationRules = {
	name: { notEmpty: true, },
	postalCode: { notEmpty: true, },
	city: { notEmpty: true, },
	address: { notEmpty: true, },
	country: { notEmpty: true, },
};

export const RegisterCompanyInfoInitial: RegisterCompanyInfo = {
	name: "",
	postalCode: "",
	city: "",
	address: "",
	country: "",
};

export type Dictionaries={
	documentTypes: Array<LangOption>;
	insurers: Array<LangOption>;
	currencies: Array<LangOption>;
	globalCountries: Array<string>;
	stages: Array<LangOption>;
};

export type PacketInfo={
	id: string;
	name: LangString;
	desc?: string;
};

export type InvoiceSynchronizationType = 'Reset'|'DataIntegrator'|'Import'|'wFirma'|'iFirma'|'Fakturownia'|'inFakt'|'SaldeoSmart';
export type InvoiceMonitoringSubscriptionType = 'Free'|'Mini'|'Midi'|'Maxi'|'Corporate';
export type InvoiceMonitoringOrganizationData={
	sync: InvoiceSynchronizationType;
	subscription: InvoiceMonitoringSubscriptionType;
	subscriptionDueTo: string;
	scenario: LangString;
	lastSync: string;
	invoices: number|null;
	unpaidInvoices: number|null;
	clients: number|null;
	unpaidClients: number|null;
	dom: number|null;
	periodSms: number|null;
	periodEmails: number|null;
	totalSms: number|null;
	totalEmails: number|null;
	extraSms: number|null;
	extraEmails: number|null;
	stop: number|null;
	timeFrom: number|null;
	timeTo: number|null;
	email: string;
	phone: string;
	pause: string;
	minBalance: string;
	workDaysOnly: boolean|null;
	debtCollection: boolean|null;
	amicableDebtCollection: boolean|null;
	vindicationGracePeriod: number|null;
};

export type OrganizationDetails={
	id: string;
	name: string;
	tax: string;
	address: string;
	ecId: string;
	insurer: Array<string>;
	packet: PacketInfo;
	services: Array<OrderTypeInfo>;
	vindicationsInProgress: number;
	vindicationsTotal: number;
	ordersInProgress: number;
	ordersTotal: number;
	country: string;
	city: string;
	postalCode: string;
	promoCodes: string;
	notes: { [string]: any };
	invoiceMonitoring: InvoiceMonitoringOrganizationData|null;
};

export type TextDetails={
	name: string;
	desc: string;
	variables: { [string]: string };
	rawEditor: boolean;
	title: LangString;
	content: LangString;
};

export type TextInfo={
	name: string;
	title: LangString;
	modified: string;
};

export type InvoiceMonitoringOrganizationSettings={
	orgId: string;
	type: InvoiceMonitoringSubscriptionType;
	extraSms: number|null;
	extraEmail: number|null;
	dom: number|null;
	subscriptionEnd: string;
	vindicationGracePeriod: number|null;
};

export type MonitoringStage = 'Settled'|'BeforePayment'|'SlightlyOutOfDate'|'Expired'|'HeavilyOverdue'|'ExceedingScenario'|'NoScenario'|'VindicationGrace'|'Vindication';
export type MonitoringScenarioEntry={
	days: number;
	stage: MonitoringStage;
	sms: string;
	email: string;
};

export type MonitoringScenarioDetails={
	id: string;
	name: LangString;
	desc: LangString;
	changed: string;
	enabled: boolean;
	def: boolean;
	days: number;
	entries: Array<MonitoringScenarioEntry>;
};

export type MonitoringScenarioInfo={
	id: string;
	name: LangString;
	desc: LangString;
	changed: string;
	enabled: boolean;
	def: boolean;
};

export type MonitoringTemplateInfo={
	id: string;
	type: string;
	name: string;
	title: string;
	changed: string;
	style: MonitoringStage;
};

export type MonitoringTemplateDetails={
	id: string;
	type: string;
	name: string;
	title: string;
	changed: string;
	style: MonitoringStage;
	content: string;
	used: boolean;
};

export type InvoiceMonitoringTemplateVariables={
	sms: { [string]: string };
	email: { [string]: string };
};

export type OrderTypeDetails={
	id: string;
	ident: string;
	type: InternalServices;
	name: LangString;
	desc: LangString;
	email: string;
	pricesPl: string;
	pricesEn: string;
	order: number;
	showPl: boolean;
	showEn: boolean;
};

export const OrderTypeDetailsValidation: ValidationRules = {
	id: { },
	ident: { notEmpty: true, },
	type: { },
	name: { notEmpty: true, },
	desc: { notEmpty: true, },
	email: { notEmpty: true, },
	pricesPl: { notEmpty: true, },
	pricesEn: { notEmpty: true, },
	order: { },
	showPl: { },
	showEn: { },
};

export const OrderTypeDetailsInitial: OrderTypeDetails = {
	id: "",
	ident: "",
	type: '',
	name: { pl: '', en: '', },
	desc: { pl: '', en: '', },
	email: "",
	pricesPl: "",
	pricesEn: "",
	order: 0,
	showPl: false,
	showEn: false,
};

export type AccessRight={
	id: string|null;
	ident: string;
	typeId: string;
	type: InternalServices|null;
	name: LangString;
	desc: LangString;
	settings: { [string]: any }|null;
	order: number;
	show: { [Language]: boolean|null };
	filter: Array<FieldFilter>|null;
	read: boolean;
	write: boolean;
	limit: number|null;
	prices: LangString;
};

export type UserRight = 'Admin';
export type UserOrganizationData={
	id: string;
	userId: string;
	name: string;
	email: string;
	orgId: string;
	org: string;
	orgTax: string;
	mode: boolean|null;
	status: UOStatus;
	services: Array<AccessRight>;
	rights: Array<UserRight>;
};

export type OrganizationData={
	id: string;
	ecId: string;
	name: string;
	tax: string;
	added: string;
	status: OrganizationStatus;
	origin: string;
	packet: string;
	promoCodes: string;
	address: string;
	country: string;
	city: string;
	postalCode: string;
	insurers: Array<string>;
	demo: boolean;
	users: Array<UserOrganizationData>;
	services: Array<OrderTypeInfo>;
};

export const OrganizationDataValidation: ValidationRules = {
	id: { },
	ecId: { },
	name: { notEmpty: true, },
	tax: { notEmpty: true, },
	added: { },
	status: { notNull: true, },
	origin: { },
	packet: { },
	promoCodes: { },
	address: { notEmpty: true, },
	country: { notEmpty: true, },
	city: { notEmpty: true, },
	postalCode: { notEmpty: true, },
	insurers: { },
	demo: { },
	users: { },
	services: { },
};

export const OrganizationDataInitial: OrganizationData = {
	id: "",
	ecId: "",
	name: "",
	tax: "",
	added: "",
	status: '',
	origin: "",
	packet: "",
	promoCodes: "",
	address: "",
	country: "",
	city: "",
	postalCode: "",
	insurers: [],
	demo: false,
	users: [],
	services: [],
};

export type OrganizationInfo={
	id: string;
	ecId: string;
	name: string;
	tax: string;
	added: string;
	status: OrganizationStatus;
	origin: string;
	packet: LangString;
	promoCodes: string;
};

export const OrganizationInfoValidation: ValidationRules = {
	id: { },
	ecId: { },
	name: { notEmpty: true, },
	tax: { notEmpty: true, },
	added: { },
	status: { notNull: true, },
	origin: { },
	packet: { },
	promoCodes: { },
};

export const OrganizationInfoInitial: OrganizationInfo = {
	id: "",
	ecId: "",
	name: "",
	tax: "",
	added: "",
	status: '',
	origin: "",
	packet: { pl: '', en: '', },
	promoCodes: "",
};

export type PacketDetails={
	id: string;
	name: LangString;
	desc: string;
	access: Array<AccessRight>;
	viewMenuMonitoring: boolean;
	viewMenuKnowledge: boolean;
};

export const PacketDetailsValidation: ValidationRules = {
	id: { },
	name: { notEmpty: true, },
	desc: { },
	access: { },
	viewMenuMonitoring: { },
	viewMenuKnowledge: { },
};

export const PacketDetailsInitial: PacketDetails = {
	id: "",
	name: { pl: '', en: '', },
	desc: "",
	access: [],
	viewMenuMonitoring: false,
	viewMenuKnowledge: false,
};

export type RoleDetails={
	id: string;
	name: string;
	desc: string;
	rights: Array<SystemRight>;
};

export const RoleDetailsValidation: ValidationRules = {
	id: { },
	name: { notEmpty: true, notNull: true, },
	desc: { },
	rights: { },
};

export const RoleDetailsInitial: RoleDetails = {
	id: "",
	name: "",
	desc: "",
	rights: [],
};

export type UserAgreementInfo={
	id: string;
	group: LangString;
	name: LangString;
	date: string;
	withdraw: string;
	order: number;
	required: boolean;
};

export type NotificationType = 'VindicationNew'|'VindicationPayment'|'VindicationFile'|'VindicationAction'|'VindicationAccepted'|'VindicationRejected'|'VindicationStage'|'VindicationReceivable'|'OrderNew'|'OrderAccepted'|'OrderRejected'|'OrderDone'|'MonitoredAdded'|'MonitoredRemoved'|'MonitoredDebt'|'MonitoredImsig'|'UserAdded'|'UserChanged'|'DebtExchangeBuy'|'OrganizationAccepted'|'IMPaymentFailed'|'IMOutOfSync'|'IMSubscriptionNearEnd'|'IMSubscriptionEnded'|'IMOutOfSMS'|'IMOutOfEmail'|'IMClientExceedingScenario'|'IMClientVindication'|'IMExtraChange'|'IMInvoiceOverdue';
export type NotificationMode = 'Summary'|'Notification'|'Email';
export type UserNotification={
	type: NotificationType;
	orderId?: string;
	mode: Array<NotificationMode>;
};

export type UserData={
	id: string;
	name: string;
	phone: string;
	email: string;
	password?: string;
	demo: boolean;
	agreements: Array<UserAgreementInfo>;
	organizations: Array<UserOrganizationData>;
	notifications: Array<UserNotification>;
};

export const UserDataValidation: ValidationRules = {
	id: { },
	name: { notEmpty: true, notNull: true, },
	phone: { },
	email: { notEmpty: true, notNull: true, },
	password: { },
	demo: { },
	agreements: { },
	organizations: { },
	notifications: { },
};

export const UserDataInitial: UserData = {
	id: "",
	name: "",
	phone: "",
	email: "",
	password: "",
	demo: false,
	agreements: [],
	organizations: [],
	notifications: [],
};

export type Mode = 'SSL'|'TLS'|'NORMAL';
export type MailerConfig={
	hostname: string;
	port: number;
	mode: Mode;
	username: string;
	password: string;
	fromEmail: string;
	fromName: string;
};

export type BirConfig={
	key: string;
};

export type IPApiConfig={
	key: string;
};

export type AccountsConfig={
	resetPasswordTimeout: number;
};

export type FreeDaysConfig={
	days: Array<string>;
};

export type SystemSettings={
	smtp: MailerConfig;
	bir: BirConfig;
	ipapi: IPApiConfig;
	accounts: AccountsConfig;
	freeDays: FreeDaysConfig;
};

export type TechnicalMessage={
	enabled: boolean;
	message: LangString;
};

export type OrderInfo={
	id: string;
	typeId: string;
	type: LangString;
	internal: InternalServices;
	clientEcId: string|null;
	clientName: string;
	clientTax: string;
	clientUser: string;
	clientFirstName: string;
	clientLastName: string;
	name: string;
	tax: string;
	added: string;
	status: OrderStatus;
};

export type AdminUserOrganizationInfo={
	uoId: string;
	uId: string;
	orgId: string;
	country: string;
};

export type VindicationEvent={
	vId?: string;
	vFullId?: string;
	vEcId?: string;
	vExtId?: string;
	vDebtor?: string;
	id: string;
	type: string;
	date: string;
	message: string;
	fileId?: string;
	fileName?: string;
	fileDate?: string;
};

export type VindicationPaymentAllocation={
	doc: string;
	capital: string;
	interest: string;
	costs: string;
};

export type VindicationPayment={
	vId?: string;
	vFullId?: string;
	vEcId?: string;
	vExtId?: string;
	vDebtor?: string;
	date: string;
	amount: string;
	message: string;
	capital: string;
	interest: string;
	costs: string;
	waiting: boolean;
	allocations: Array<VindicationPaymentAllocation>;
};

export type VindicationViewFile={
	vId?: string;
	vFullId?: string;
	vEcId?: string;
	vExtId?: string;
	vDebtor?: string;
	dbId: string;
	type: string;
	name: string;
	date: string;
	description: string;
};

export type VindicationViewReceivable={
	initialCapital: string;
	initialInterests: string;
	initialCosts: string;
	actualCapital: string;
	actualInterests: string;
	actualCosts: string;
	recoveredCapital: string;
	recoveredInterests: string;
	recoveredCosts: string;
	vId?: string;
	type: LangString;
	number: string;
	sellDate: string;
	paymentDate: string;
	initial: string;
	actual: string;
	recovered: string;
};

export type DebtExchangeInfo={
	id: string;
	date: string;
	name: string;
	tax: string;
	city: string;
	value: string;
	sellValue: string;
	actualCapital: string;
	recovered: string;
	actual: string;
	currency: string;
	risky: boolean;
};

export type VindicationViewCost={
	date: string;
	stage: string;
	type: string;
	amount: string;
	description: string;
	affectsBalance: boolean;
	invoiced: boolean;
	invoice: string;
	currency: string;
};

export type VindicationDetails={
	initialCapital: string;
	initialInterests: string;
	initialCosts: string;
	actualCapital: string;
	actualInterests: string;
	actualCosts: string;
	recoveredCapital: string;
	recoveredInterests: string;
	recoveredCosts: string;
	id: string;
	fullId: string;
	added: string;
	ecId: string;
	tax: string;
	debtor: string;
	extId: string;
	currency: string;
	changed: string;
	stageChanged: string;
	status: OrderStatus;
	stage: string;
	ecStatus: string|null;
	amicable: boolean;
	judicial: boolean;
	domestic: boolean;
	chargeInterests: boolean;
	chargeCosts: boolean;
	invoiceMonitoring: boolean;
	invoiceMonitoringAutomatic: boolean;
	creditor?: string;
	/** Ext */
	client?: string;
	/** Ext */
	clientEcId?: string|null;
	/** Ext */
	clientFistName?: string;
	/** Ext */
	clientLastName?: string;
	/** Ext */
	clientTax?: string;
	/** Ext */
	rejectionReason?: LangString;
	/** Ext */
	acceptedBy?: string;
	/** Ext */
	worker?: string;
	/** Ext */
	changed2?: string;
	grace: string;
	address: string;
	postalCode: string;
	city: string;
	country: string;
	insurer: string;
	events: Array<VindicationEvent>;
	payments: Array<VindicationPayment>;
	files: Array<VindicationViewFile>;
	receivables: Array<VindicationViewReceivable>;
	receivablesFile: VindicationViewFile;
	vindications: Array<VindicationTableInfo>;
	otherVindications: Array<DebtExchangeInfo>;
	costs: Array<VindicationViewCost>;
	courtRefNumber: string;
	bailiffRefNumber: string;
	executiveApplicationDate: string;
	creditorName: string;
	creditorAddress: string;
	creditorCountry: string;
	/** Ext */
	alreadyVindicated?: boolean;
	/** Ext */
	otherContacts?: string;
	/** Ext */
	phone?: string;
	/** Ext */
	email?: string;
	/** Ext */
	pricesFile?: string;
	/** Ext */
	pricesFileName?: string;
	initial: string;
	actual: string;
	recovered: string;
};

export type VindicationDictionaries={
	cities: Array<string>;
	currencies: Array<string>;
	statuses: Array<string>;
	countries: Array<string>;
	tax: Array<string>;
	stages: Array<string>;
};

export type SearchTarget = 'Vindications'|'UserOrganizations'|'Organizations'|'Orders';
export type SearchResult={
	target: SearchTarget;
	id: string;
	orderType: string;
	client: string;
	clientTax: string;
	label: string;
};

export type UserInfo={
	id: string;
	name: string;
	email: string;
	registered: string;
	lastLogged: string;
};

export type UserOrganizationInfo={
	id: string;
	status: UOStatus;
	lastOrgLogged: string;
	userId: string;
	name: string;
	email: string;
	registered: string;
	lastLogged: string;
	orgId: string;
	org: string;
	orgTax: string;
};

export type VindicationDocument={
	type: string;
	number: string;
	amount: string;
	sell: string;
	payment: string;
	invoiceId: string;
};

export type VindicationFile={
	dbId: string;
	customName: string;
};

export type VindicationContact={
	emailSend: boolean;
	email: string;
	phoneSend: boolean;
	phone: string;
};

export type VindicationCheckedContact={
	checked: boolean;
	value: string;
};

export type VindicationRegisterData={
	vindicationId: string;
	clientId: string;
	tax: string;
	name: string;
	postalCode: string;
	city: string;
	address: string;
	country: string;
	documents: Array<VindicationDocument>;
	documentsDbId: string;
	insurer: string|null;
	domestic: boolean;
	currency: string;
	amicable: boolean;
	judicial: boolean;
	interest: boolean;
	costs: boolean;
	extId: string;
	interestRate: string|null;
	files: Array<VindicationFile>;
	otherContacts: string;
	email: string;
	phone: string;
	contacts: Array<VindicationContact>;
	emails: Array<VindicationCheckedContact>;
	phones: Array<VindicationCheckedContact>;
	creditorName: string;
	creditorAddress: string;
	creditorCountry: string;
};

export const VindicationRegisterDataValidation: ValidationRules = {
	vindicationId: { },
	clientId: { },
	tax: { },
	name: { notEmpty: true, notNull: true, },
	postalCode: { notEmpty: true, notNull: true, },
	city: { notEmpty: true, notNull: true, },
	address: { notEmpty: true, notNull: true, },
	country: { notEmpty: true, notNull: true, },
	documents: { notEmpty: true, },
	documentsDbId: { },
	insurer: { },
	domestic: { },
	currency: { notNull: true, },
	amicable: { },
	judicial: { },
	interest: { },
	costs: { },
	extId: { },
	interestRate: { },
	files: { },
	otherContacts: { },
	email: { },
	phone: { },
	contacts: { },
	emails: { },
	phones: { },
	creditorName: { },
	creditorAddress: { },
	creditorCountry: { },
};

export const VindicationRegisterDataInitial: VindicationRegisterData = {
	vindicationId: "",
	clientId: "",
	tax: "",
	name: "",
	postalCode: "",
	city: "",
	address: "",
	country: "",
	documents: [],
	documentsDbId: "",
	insurer: null,
	domestic: false,
	currency: "",
	amicable: false,
	judicial: false,
	interest: false,
	costs: false,
	extId: "",
	interestRate: null,
	files: [],
	otherContacts: "",
	email: "",
	phone: "",
	contacts: [],
	emails: [],
	phones: [],
	creditorName: "",
	creditorAddress: "",
	creditorCountry: "",
};

export type OrderRegistrationData={
	name: string;
	nip: string;
	desc: string;
};

export const OrderRegistrationDataValidation: ValidationRules = {
	name: { notEmpty: true, },
	nip: { },
	desc: { },
};

export const OrderRegistrationDataInitial: OrderRegistrationData = {
	name: "",
	nip: "",
	desc: "",
};

export type AdminSelfForm={
	name: string;
	email: string;
	passwordVisible: boolean;
	newPassword: string;
	repeatPassword: string;
};

export const AdminSelfFormValidation: ValidationRules = {
	name: { notEmpty: true, },
	email: { },
	passwordVisible: { },
	newPassword: { },
	repeatPassword: { },
};

export const AdminSelfFormInitial: AdminSelfForm = {
	name: "",
	email: "",
	passwordVisible: false,
	newPassword: "",
	repeatPassword: "",
};

export interface SysUserAPI {
	acceptOrganization(id: string, ecId: string, packetId: string, origin: string): Promise<void|ErrorResult>;
	addDifficultDebtFiles(langCode: string, files: Array<DifficultDebtFile>): Promise<void>;
	changeMultipleOrganizationStatus(ids: Array<string>, status: OrganizationStatus): Promise<number|ErrorResult>;
	changeMultipleUserInOrganizationStatus(uoIds: Array<string>, status: UOStatus): Promise<number>;
	changeOrganizationStatus(id: string, status: OrganizationStatus): Promise<void|ErrorResult>;
	changePacket(orgId: string, packetId: string): Promise<void|ErrorResult>;
	changePassword(newPassword: string): Promise<void|ErrorResult>;
	changeUserInOrganizationStatus(uoId: string, status: UOStatus): Promise<void>;
	changeUserStatus(id: string, status: UserStatus): Promise<void>;
	checkDocuments(uoId: string, docs: Array<ReceivableInfo>): Promise<Array<string>>;
	deleteBanner(id: string): Promise<void>;
	deleteDictionaryEntry(type: DictionaryType, value: WebDictionaryEntry): Promise<void>;
	deleteDifficultDebtFile(id: string): Promise<void>;
	deleteMonitoringScenario(id: string): Promise<boolean>;
	deleteMultipleOrganization(ids: Array<string>): Promise<number|ErrorResult>;
	deleteMultipleUser(ids: Array<string>): Promise<number>;
	deleteNotificationTemplate(id: string): Promise<boolean>;
	deleteOrderType(id: string): Promise<void|ErrorResult>;
	deleteOrganization(id: string): Promise<void|ErrorResult>;
	deletePacket(id: string): Promise<boolean>;
	deleteRejectionReason(id: string): Promise<void>;
	deleteRole(id: string): Promise<void>;
	deleteUser(id: string): Promise<void>;
	disableAllInvoicesBefore(orgId: string, date: string): Promise<number>;
	enableTechnicalMessage(enabled: boolean): Promise<void>;
	exportOrganizations(query: TableQuery): Promise<FileInfo>;
	exportUsers(query: TableQuery): Promise<FileInfo>;
	exportUsersInOrganizations(query: TableQuery): Promise<FileInfo>;
	exportVindications(query: TableQuery): Promise<FileInfo>;
	getAdmin(id: string): Promise<AdminUser>;
	getAdminRights(): Promise<Array<Option>>;
	getAdmins(): Promise<Array<AdminInfo>>;
	getAgreements(): Promise<Array<AgreementInfo>>;
	getAllDifficultDebts(): Promise<Array<DifficultDebtInfo>>;
	getAllRejectionReasons(): Promise<Array<RejectionReasonInfo>>;
	getAllRoles(): Promise<Array<RoleInfo>>;
	getBanner(id: string): Promise<BannerInfo>;
	getBanners(): Promise<Array<BannerInfo>>;
	getCompanyInfo(country: string, tax: string): Promise<RegisterCompanyInfo>;
	getDictionaries(): Promise<Dictionaries>;
	getDisplayOrganization(id: string): Promise<OrganizationDetails>;
	getDocumentTypes(): Promise<Array<LangOption>>;
	getEditDictionary(type: DictionaryType): Promise<Array<WebDictionaryEntry>>;
	getEditDictionaryEntry(type: DictionaryType, id: string): Promise<WebDictionaryEntry>;
	getEmailTemplate(name: string): Promise<TextDetails>;
	getEmailTemplates(): Promise<Array<TextInfo>>;
	getInsurers(): Promise<Array<LangOption>>;
	getInvoiceMonitoringSettings(orgId: string): Promise<InvoiceMonitoringOrganizationSettings>;
	getLangKeys(): Promise<{ [string]: LangString }>;
	getMonitoringScenario(id: string): Promise<MonitoringScenarioDetails>;
	getMonitoringScenarios(): Promise<Array<MonitoringScenarioInfo>>;
	getNotificationsTemplates(): Promise<Array<MonitoringTemplateInfo>>;
	getNotificationTemplate(id: string): Promise<MonitoringTemplateDetails>;
	getNotificationTemplateVariables(): Promise<InvoiceMonitoringTemplateVariables>;
	getOrderType(id: string): Promise<OrderTypeDetails>;
	getOrderTypes(): Promise<Array<OrderTypeInfo>>;
	getOrganization(id: string): Promise<OrganizationData>;
	getOrganizationInsurers(uoId: string): Promise<Array<LangOption>>;
	getOrganizationOrigins(): Promise<Array<string>>;
	getOrganizations(query: TableQuery): Promise<TableResult<OrganizationInfo>>;
	getOtherForVindication(id: string): Promise<Array<VindicationTableInfo>>;
	getPacket(id: string): Promise<PacketDetails>;
	getPackets(): Promise<Array<PacketInfo>>;
	getRejectionReason(id: string): Promise<RejectionReasonInfo>;
	getRemoteTokenLink(token: string): Promise<string>;
	getRole(id: string): Promise<RoleDetails>;
	getSimpleUser(uId: string): Promise<UserData>;
	getStats(): Promise<{ connected?: boolean, [string]: { [string]: number } }>;
	getSystemSettings(): Promise<SystemSettings>;
	getTechnicalMessage(): Promise<TechnicalMessage>;
	getTextPageDetails(name: string): Promise<TextDetails>;
	getTextPages(): Promise<Array<TextInfo>>;
	getUnassignedOrders(): Promise<Array<OrderInfo>>;
	getUser(uoId: string): Promise<UserData>;
	getUserOrganizationInfo(uoId: string): Promise<AdminUserOrganizationInfo>;
	getUserServices(uoId: string): Promise<Array<AccessRight>>;
	getUsersRights(): Promise<Array<LangOption>>;
	getUserToOrganizationStruct(uId: string, oId: string): Promise<UserOrganizationData>;
	getVindication(id: string): Promise<VindicationDetails>;
	getVindicationDictionaries(): Promise<VindicationDictionaries>;
	getVindicationsForDebtor(uoId: string, nip: string): Promise<Array<VindicationTableInfo>>;
	globalSearch(query: string, target: SearchTarget): Promise<Array<SearchResult>>;
	invoiceMonitoringSendMessages(orgId: string, from: string, to: string): Promise<FileInfo>;
	invoiceMonitoringSubscription(orgId: string, from: string, to: string): Promise<FileInfo>;
	logout(): Promise<void>;
	monitoredPerOrganizationReport(): Promise<FileInfo>;
	monitoringEventsInOrganization(orgId: string): Promise<FileInfo>;
	multipleOrderAccept(ids: Array<string>): Promise<number>;
	multipleOrderReject(ids: Array<string>): Promise<number>;
	orderAccept(id: string): Promise<void>;
	orderMarkAsDone(id: string): Promise<void>;
	orderReject(id: string): Promise<void>;
	queryOrders(query: TableQuery): Promise<TableResult<OrderInfo>>;
	queryUsers(query: TableQuery): Promise<TableResult<UserInfo>>;
	queryUsersInOrganizations(query: TableQuery): Promise<TableResult<UserOrganizationInfo>>;
	queryVindications(query: TableQuery): Promise<TableResult<VindicationTableInfo>>;
	registerMultipleVindications(uoId: string, vindications: Array<VindicationRegisterData>): Promise<void|ErrorResult>;
	registerOrder(uoId: string, typeId: string, data: OrderRegistrationData): Promise<void|ErrorResult>;
	registerVindication(uoId: string, vindication: VindicationRegisterData): Promise<Array<string>|ErrorResult>;
	saveAdmin(user: AdminUser): Promise<void|ErrorResult>;
	saveBanner(b: BannerInfo): Promise<void|ErrorResult>;
	saveDictionaryEntry(type: DictionaryType, value: WebDictionaryEntry): Promise<void>;
	saveEmailTemplate(name: string, title: LangString, content: LangString): Promise<void|ErrorResult>;
	saveInvoiceMonitoring(settings: InvoiceMonitoringOrganizationSettings): Promise<void|ErrorResult>;
	saveOrganization(org: OrganizationData): Promise<void|ErrorResult>;
	saveRole(role: RoleDetails): Promise<void|ErrorResult>;
	saveSimpleUser(webUser: UserData): Promise<void|ErrorResult>;
	saveTextPage(name: string, title: LangString, content: LangString): Promise<void>;
	saveUser(webUser: UserData): Promise<void|ErrorResult>;
	sendEmailTemplate(lang: string, name: string, title: string, content: string): Promise<void|ErrorResult>;
	sendTestEmail(to: string): Promise<string>;
	suggestOrganizationForUser(userId: string, query: string): Promise<Array<Option>>;
	suggestUserForOrganization(orgId: string, query: string): Promise<Array<Option>>;
	updateAccountsSettings(accounts: AccountsConfig): Promise<void>;
	updateBirSettings(bir: BirConfig): Promise<void>;
	updateEmailSettings(smtp: MailerConfig): Promise<void>;
	updateIPApiSettings(ipapi: IPApiConfig): Promise<void>;
	updateLangKeys(changes: { [string]: LangString }): Promise<void>;
	updateMonitoringScenario(id: string, form: MonitoringScenarioDetails): Promise<string>;
	updateNotificationTemplate(id: string, stage: MonitoringStage, title: string, content: string): Promise<string>;
	updateOrderType(service: OrderTypeDetails): Promise<void|ErrorResult>;
	updatePacket(packet: PacketDetails): Promise<void>;
	updateRejectionReason(reason: RejectionReasonInfo): Promise<void|ErrorResult>;
	updateSelf(info: AdminSelfForm): Promise<void|ErrorResult>;
	updateTechnicalMessage(tm: TechnicalMessage): Promise<void>;
	updateVindicationContacts(uoId: string, vId: string, data: VindicationRegisterData): Promise<void|ErrorResult>;
	usersInOrganizationReport(): Promise<FileInfo>;
	vindicationAccept(id: string, otherId: string, ecId: string): Promise<void|ErrorResult>;
	vindicationDone(id: string): Promise<void>;
	vindicationMultipleAccept(ids: Array<string>): Promise<number|ErrorResult>;
	vindicationMultipleReject(ids: Array<string>, reasonId: string, reason: string): Promise<number|ErrorResult>;
	vindicationReject(id: string, reasonId: string, reason: string): Promise<void|ErrorResult>;
	vindicationUpdateTax(id: string, tax: string): Promise<void>;
}

export type SysUserAPIQuery = {
	acceptOrganization: void|ErrorResult,
	addDifficultDebtFiles: void,
	changeMultipleOrganizationStatus: number|ErrorResult,
	changeMultipleUserInOrganizationStatus: number,
	changeOrganizationStatus: void|ErrorResult,
	changePacket: void|ErrorResult,
	changePassword: void|ErrorResult,
	changeUserInOrganizationStatus: void,
	changeUserStatus: void,
	checkDocuments: Array<string>,
	deleteBanner: void,
	deleteDictionaryEntry: void,
	deleteDifficultDebtFile: void,
	deleteMonitoringScenario: boolean,
	deleteMultipleOrganization: number|ErrorResult,
	deleteMultipleUser: number,
	deleteNotificationTemplate: boolean,
	deleteOrderType: void|ErrorResult,
	deleteOrganization: void|ErrorResult,
	deletePacket: boolean,
	deleteRejectionReason: void,
	deleteRole: void,
	deleteUser: void,
	disableAllInvoicesBefore: number,
	enableTechnicalMessage: void,
	exportOrganizations: FileInfo,
	exportUsers: FileInfo,
	exportUsersInOrganizations: FileInfo,
	exportVindications: FileInfo,
	getAdmin: AdminUser,
	getAdminRights: Array<Option>,
	getAdmins: Array<AdminInfo>,
	getAgreements: Array<AgreementInfo>,
	getAllDifficultDebts: Array<DifficultDebtInfo>,
	getAllRejectionReasons: Array<RejectionReasonInfo>,
	getAllRoles: Array<RoleInfo>,
	getBanner: BannerInfo,
	getBanners: Array<BannerInfo>,
	getCompanyInfo: RegisterCompanyInfo,
	getDictionaries: Dictionaries,
	getDisplayOrganization: OrganizationDetails,
	getDocumentTypes: Array<LangOption>,
	getEditDictionary: Array<WebDictionaryEntry>,
	getEditDictionaryEntry: WebDictionaryEntry,
	getEmailTemplate: TextDetails,
	getEmailTemplates: Array<TextInfo>,
	getInsurers: Array<LangOption>,
	getInvoiceMonitoringSettings: InvoiceMonitoringOrganizationSettings,
	getLangKeys: { [string]: LangString },
	getMonitoringScenario: MonitoringScenarioDetails,
	getMonitoringScenarios: Array<MonitoringScenarioInfo>,
	getNotificationsTemplates: Array<MonitoringTemplateInfo>,
	getNotificationTemplate: MonitoringTemplateDetails,
	getNotificationTemplateVariables: InvoiceMonitoringTemplateVariables,
	getOrderType: OrderTypeDetails,
	getOrderTypes: Array<OrderTypeInfo>,
	getOrganization: OrganizationData,
	getOrganizationInsurers: Array<LangOption>,
	getOrganizationOrigins: Array<string>,
	getOrganizations: TableResult<OrganizationInfo>,
	getOtherForVindication: Array<VindicationTableInfo>,
	getPacket: PacketDetails,
	getPackets: Array<PacketInfo>,
	getRejectionReason: RejectionReasonInfo,
	getRemoteTokenLink: string,
	getRole: RoleDetails,
	getSimpleUser: UserData,
	getStats: { connected?: boolean, [string]: { [string]: number } },
	getSystemSettings: SystemSettings,
	getTechnicalMessage: TechnicalMessage,
	getTextPageDetails: TextDetails,
	getTextPages: Array<TextInfo>,
	getUnassignedOrders: Array<OrderInfo>,
	getUser: UserData,
	getUserOrganizationInfo: AdminUserOrganizationInfo,
	getUserServices: Array<AccessRight>,
	getUsersRights: Array<LangOption>,
	getUserToOrganizationStruct: UserOrganizationData,
	getVindication: VindicationDetails,
	getVindicationDictionaries: VindicationDictionaries,
	getVindicationsForDebtor: Array<VindicationTableInfo>,
	globalSearch: Array<SearchResult>,
	invoiceMonitoringSendMessages: FileInfo,
	invoiceMonitoringSubscription: FileInfo,
	logout: void,
	monitoredPerOrganizationReport: FileInfo,
	monitoringEventsInOrganization: FileInfo,
	multipleOrderAccept: number,
	multipleOrderReject: number,
	orderAccept: void,
	orderMarkAsDone: void,
	orderReject: void,
	queryOrders: TableResult<OrderInfo>,
	queryUsers: TableResult<UserInfo>,
	queryUsersInOrganizations: TableResult<UserOrganizationInfo>,
	queryVindications: TableResult<VindicationTableInfo>,
	registerMultipleVindications: void|ErrorResult,
	registerOrder: void|ErrorResult,
	registerVindication: Array<string>|ErrorResult,
	saveAdmin: void|ErrorResult,
	saveBanner: void|ErrorResult,
	saveDictionaryEntry: void,
	saveEmailTemplate: void|ErrorResult,
	saveInvoiceMonitoring: void|ErrorResult,
	saveOrganization: void|ErrorResult,
	saveRole: void|ErrorResult,
	saveSimpleUser: void|ErrorResult,
	saveTextPage: void,
	saveUser: void|ErrorResult,
	sendEmailTemplate: void|ErrorResult,
	sendTestEmail: string,
	suggestOrganizationForUser: Array<Option>,
	suggestUserForOrganization: Array<Option>,
	updateAccountsSettings: void,
	updateBirSettings: void,
	updateEmailSettings: void,
	updateIPApiSettings: void,
	updateLangKeys: void,
	updateMonitoringScenario: string,
	updateNotificationTemplate: string,
	updateOrderType: void|ErrorResult,
	updatePacket: void,
	updateRejectionReason: void|ErrorResult,
	updateSelf: void|ErrorResult,
	updateTechnicalMessage: void,
	updateVindicationContacts: void|ErrorResult,
	usersInOrganizationReport: FileInfo,
	vindicationAccept: void|ErrorResult,
	vindicationDone: void,
	vindicationMultipleAccept: number|ErrorResult,
	vindicationMultipleReject: number|ErrorResult,
	vindicationReject: void|ErrorResult,
	vindicationUpdateTax: void,
};


//@flow
/* eslint-disable no-use-before-define */
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {RouteChildrenProps} from "react-router";
import msgs, {currentLang, formatString, getLangValue, getMessage, LangContext, useMsgs} from "../lib/Language";
import {Breadcrumb, Button, Col, Nav, Row, Tab, Tabs} from "react-bootstrap";
import {BreadcrumbItem, Dialog, dialogOpen, PageHeader, waitGlass} from "../lib/Components";
import type {
    AccessRight, Dictionaries,
    FieldFilter,
    OrderTypeInfo,
    UOStatus,
    UserAgreementInfo,
    UserData, UserInfo,
    UserOrganizationData,
    UserOrganizationInfo,
    UserRight
} from "../api";
import {TableQuery, UserDataValidation} from "../api";
import HomeScreen from "./HomeScreen";
import {Events, events, store} from "../application";
import type {DataTableColumn, DataTableRowSelection} from "../lib/DataTable";
import DataTable from "../lib/DataTable";
import {formatDateTime} from "../lib/DateTime";
import {Form} from "../lib/Form";
import {UserCompanySettings} from "../lib/UserCompanySettings";
import Notifications from "../lib/Notifications";
import Autosuggest from "react-autosuggest";
import {getLocationState, getOptionLabel, replaceHistoryState} from "../lib/Utils";
import Validate from "../lib/Validation";
import ClientOrderButton from "./ClientOrder";
import {getDownloadLink} from "../lib/Upload";
import {useHistory, useLocation} from "react-router-dom";

type Props = RouteChildrenProps & {

}

function renderSuggestion(suggestion) {
    return <span>{suggestion.label}</span>
}

/**
 * Komponent do podpowiadania/wybierani organizacji.
 */
const OrganizationSuggest = ({ value, onAddOrganization }: {
    value: string;
    onAddOrganization: (orgId: string) => void;
}) => {
    const [suggestions, setSuggestions] = useState([]);
    const [query, setQuery] = useState("");
    const [selected, setSelected]=useState(null);
    const req = useRef(0);
    const queryFunc = useCallback((query) => {
        const r = ++req.current;
        store.userApi.suggestOrganizationForUser(value, query.value).then(res => {
            if (r !== req.current) return;
            setSuggestions(res);
        })
    }, [value]);
    const clearFunc = useCallback(() => {
        setSuggestions([]);
    }, [])
    const handleQueryChange = useCallback((_, {newValue}) => {
        setQuery(newValue);
        setSelected(null);
    }, []);
    return <div className="d-flex">
        <Autosuggest
            id="suggest-organization"
            suggestions={suggestions}
            getSuggestionValue={getOptionLabel}
            onSuggestionsFetchRequested={queryFunc}
            onSuggestionsClearRequested={clearFunc}
            renderSuggestion={renderSuggestion}
            onSuggestionSelected={(_, {suggestion}) => setSelected(suggestion.value)}
            inputProps={{
                placeholder: msgs.admin.hintAddNextOrganization,
                value: query,
                onChange: handleQueryChange
            }}
            theme={{
                container: 'autosuggest flex-grow-1',
                input: 'form-control',
                suggestionsContainer: 'dropdown',
                suggestionsList: `dropdown-menu ${suggestions.length ? 'show' : ''}`,
                suggestion: 'dropdown-item',
                suggestionHighlighted: 'active'
            }}
        />
        <Button
            variant="outline-secondary" className="ml-2 btn-circle"
            disabled={!selected}
            onClick={() => {
                onAddOrganization(selected);
                setSelected(null);
            }}
        >+</Button>
    </div>
}

export const SimpleUsersScreen = () => {
    const msgs=useMsgs();
    const columns=useMemo<Array<DataTableColumn<UserInfo>>>(() => {
        return [
            {
                accessor: "id",
                Header: msgs.gui.labelID,
                filter: "number",
                className: "id",
            }, {
                accessor: "name",
                Header: <>{msgs.gui.labelFullName}<br/>{msgs.gui.labelCompany}</>,
                Cell: ({ row }) => <>
                    <h1>{row.original.name}</h1>
                    <span className="d-block">{row.original.orgTax}</span>
                    {row.original.org}
                </>,
                filter: "text",
                className: "mw-lg",
            }, {
                accessor: "email",
                Header: msgs.gui.labelEmailLogin,
                filter: "text",
                className: "email"
            }, {
                accessor: "lastLogged",
                Header: msgs.gui.labelLastLogin,
                Cell: ({value}) => formatDateTime(value),
                filter: "date",
                className: "datetime"
            }, {
                accessor: "registered",
                Header: msgs.gui.labelRegisterDate,
                Cell: ({value}) => formatDateTime(value),
                filter: "date",
                className: "datetime"
            }
            ];
    }, [ msgs.gui.language ]);
    const [ dataTimestamp, setDataTimestamp ] = useState(() => Date.now());
    const history=useHistory();
    const location=useLocation();
    const handleRowClick = useCallback((user: UserInfo) => {
        dialogOpen(history, location, EditUserDialog.sLink(user.id), () => setDataTimestamp(Date.now()));
    }, []);
    const onAddUser = useCallback((e: Event) => {
        e.preventDefault();
        dialogOpen(history, location, EditUserDialog.sLink("-"), () => setDataTimestamp(Date.now()));
    }, []);
    const queryRef=useRef<TableQuery>(null);
    const edit=store.hasRight("UsersWrite");
    const report=store.hasRight("UsersExport");

    const queryUsers=useCallback((query: TableQuery) => {
        queryRef.current=query;   // zapamiętujemy zapytanie na potrzeby eksportu
        return store.userApi.queryUsers(query);
    }, []);


    return <>
        <Breadcrumb>
            <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
            <BreadcrumbItem active>{msgs.admin.menuUsers}</BreadcrumbItem>
        </Breadcrumb>
        <PageHeader title={msgs.admin.menuUsers}>
            {report?<Button variant="info" onClick={() => {
                if(!queryRef.current) return;
                store.userApi.exportUsers(queryRef.current).then(file => {
                    if(file) {
                        window.location.href=getDownloadLink(file.safeId, file.name);
                    }
                }).finally(waitGlass());
            }}>{msgs.gui.actionExportResults}</Button>:null}
            {edit?<Button onClick={onAddUser}>{msgs.gui.actionAddUser}</Button>:null}
        </PageHeader>
        <DataTable
            className="datatable-users"
            columns={columns}
            data={queryUsers}
            onRowClick={handleRowClick}
            dataTimestamp={dataTimestamp}
            historyState={"table_simple_users"}
            initialSortBy={"-registered"}
        />
    </>
}
SimpleUsersScreen.url="/susers";

type State = {
    tabStatus: UOStatus;
    statusFilter: Array<FieldFilter>;
    dataTimestamp: number;
    selected: DataTableRowSelection;
    selectedAmount: number;
}

export default class UsersScreen extends React.Component<Props, State> {
    static url="/users";

    columns: Array<DataTableColumn<UserOrganizationInfo>>
    edit: boolean;
    remoteHelp: boolean;
    report: boolean;
    /** Na potrzeby eksportu */
    query: TableQuery;

    constructor(props) {
        super(props);
        this.edit=store.hasRight("UsersWrite");
        this.report=store.hasRight("UsersExport");
        this.remoteHelp=store.hasRight("RemoteHelp");
        const tab=getLocationState(this.props.location, 'tab') || "New";
        this.state={
            tabStatus: tab,
            statusFilter: [{ field: 'status', value: tab }],
            dataTimestamp: 0,
            selected: null,
            selectedAmount: 0,
        }
        this.updateView(tab);
    }

    refresh() {
        this.setState({ dataTimestamp: Date.now() });
    }

    handleUserAccept(e: SyntheticEvent, uo: UserOrganizationInfo) {
        e.stopPropagation(); e.preventDefault();
        store.userApi.changeUserInOrganizationStatus(uo.id, "Active").then(() => {
            this.setState({ dataTimestamp: Date.now() })
        });
    }

    handleUserReject(e: SyntheticEvent, uo: UserOrganizationInfo) {
        e.stopPropagation(); e.preventDefault();
        store.userApi.changeUserInOrganizationStatus(uo.id, "Rejected").then(() => {
            this.setState({ dataTimestamp: Date.now() })
        });
    }

    handleUserBlock(e: SyntheticEvent, uo: UserOrganizationInfo) {
        e.stopPropagation(); e.preventDefault();
        store.userApi.changeUserInOrganizationStatus(uo.id, "Blocked").then(() => {
            this.setState({ dataTimestamp: Date.now() })
        });
    }

    handleUserTempBlock(e: SyntheticEvent, uo: UserOrganizationInfo) {
        e.stopPropagation(); e.preventDefault();
        store.userApi.changeUserInOrganizationStatus(uo.id, "TemporaryBlocked").then(() => {
            this.setState({ dataTimestamp: Date.now() })
        });
    }

    handleUserDelete(e: SyntheticEvent, uo: UserOrganizationInfo) {
        e.stopPropagation(); e.preventDefault();
        if(window.confirm(msgs.gui.deleteConfirm)) {
            store.userApi.deleteUser(uo.userId).then((err) => {
                if(Validate.isError(err)) {
                    window.alert(getMessage(Validate.getError(err)));
                    return;
                }
                this.setState({ dataTimestamp: Date.now() })
            });
        }
    }

    updateColumns(columns: { [string]: boolean }, actions: (row: UserOrganizationInfo) => React$Node) {
        this.columns=[
            {
                accessor: "userId",
                Header: msgs.gui.labelID,
                filter: "number",
                className: "id",
            }, {
                accessor: "name",
                Header: <>{msgs.gui.labelFullName}<br/>{msgs.gui.labelCompany}</>,
                Cell: ({ row }) => <>
                    <h1>{row.original.name}</h1>
                    <span className="d-block">{row.original.orgTax}</span>
                    {row.original.org}
                </>,
                filter: "text",
                className: "mw-lg",
            }, {
                accessor: "email",
                Header: msgs.gui.labelEmailLogin,
                filter: "text",
                className: "email"
            }];
        if(columns.lastLogged) {
            this.columns.push({
                accessor: "lastLogged",
                Header: msgs.gui.labelLastLogin,
                Cell: ({value}) => formatDateTime(value),
                filter: "date",
                className: "datetime"
            });
        }
        if(columns.registered) {
            this.columns.push({
                accessor: "registered",
                Header: msgs.gui.labelRegisterDate,
                Cell: ({value}) => formatDateTime(value),
                filter: "date",
                className: "datetime"
            })
        }
        if(actions) {
            this.columns.push({
                id: "actions",
                Header: msgs.gui.labelActions,
                Cell: ({row}) => actions(row.original),
                className: "actions2",
            });
        }
    }

    displayWaiting() {
        this.updateColumns({ registered: true }, (row) => this.edit?<>
            <Button
                variant="success"
                onClick={(e) => this.handleUserAccept(e, row)}
            >{msgs.gui.actionActivate}</Button> <Button
            variant="danger"
            onClick={(e) => this.handleUserReject(e, row)}
        >{msgs.gui.actionReject}</Button>
        </>:null);
    }

    displayActive() {
        this.updateColumns({ lastLogged: true }, (row) => this.edit?<>
            <Button
                variant="danger"
                onClick={(e) => this.handleUserTempBlock(e, row)}
            >{msgs.gui.actionTempBlock}</Button> <Button
            variant="danger"
            onClick={(e) => this.handleUserBlock(e, row)}
            >{msgs.gui.actionBlock}</Button>
            {this.remoteHelp?<ClientOrderButton uoId={row.id}/>:null}
        </>:null);
    }

    displayTempBlocked() {
        this.updateColumns({lastLogged: true}, (row) => this.edit ? <>
            <Button
                variant="success"
                onClick={(e) => this.handleUserAccept(e, row)}
            >{msgs.gui.actionActivate}</Button> <Button
            variant="danger"
            onClick={(e) => this.handleUserBlock(e, row)}
        >{msgs.gui.actionBlock}</Button>
        </> : null);
    }

    displayBlocked() {
        this.updateColumns({ lastLogged: true }, (row) => this.edit?<>
            <Button
                variant="success"
                onClick={(e) => this.handleUserAccept(e, row)}
            >{msgs.gui.actionActivate}</Button><Button
                variant="danger"
                onClick={(e) => this.handleUserTempBlock(e, row)}
            >{msgs.gui.actionTempBlock}</Button>
        </>:null);
    }

    displayRejected() {
        this.updateColumns({ lastLogged: true });

        // this.updateColumns({ lastLogged: true }, (row) => this.edit?<>
        // <Button
        //     variant="danger"
        //     onClick={(e) => this.handleUserDelete(e, row)}
        // >{msgs.gui.actionDelete}</Button>
        // </>:null);
    }

    updateView(status: UOStatus) {
        switch(status) {
            case "New":
                this.displayWaiting();
                break;
            case "Active":
                this.displayActive();
                break;
            case "TemporaryBlocked":
                this.displayTempBlocked();
                break;
            case "Blocked":
                this.displayBlocked();
                break;
            case "Rejected":
                this.displayRejected();
                break;
        }

    }

    shouldComponentUpdate(nextProps: $ReadOnly<Props>, nextState: $ReadOnly<State>, nextContext: any): boolean {
        if(this.state.tabStatus!==nextState.tabStatus) {
            this.updateView(nextState.tabStatus);
        }
        return true;
    }

    onAddUser(e: Event) {
        e.preventDefault();
        dialogOpen(this, EditUserDialog.link("-"), () => this.refresh());
    }

    handleRowClick = (user: UserOrganizationInfo) => {
        dialogOpen(this, EditUserDialog.link(user.id), () => this.refresh());
    }

    handleSelectionChange=(selected: DataTableRowSelection) => {
        this.setState({
            selected: selected,
            selectedAmount: Object.keys(selected).length
        })
    }

    handleChangeUsersInOrganization(status: UOStatus) {
        store.userApi.changeMultipleUserInOrganizationStatus(Object.keys(this.state.selected), status).then(res => {
            this.refresh();
            events.emit(Events.UserOrganizationChange);
        })
    }

    renderSelectedActions() {
        if(this.state.tabStatus!=="New" || this.state.selectedAmount===0) return null;
        return <>
            <Button variant="link" onClick={() => this.handleChangeUsersInOrganization("Rejected")}>{msgs.admin.actionRejectSelected}</Button>
            <Button variant="link" onClick={() => this.handleChangeUsersInOrganization("Active")}>{msgs.admin.actionAcceptSelected}</Button>
        </>;
    }

    exportResults() {
        if(!this.query) return;
        store.userApi.exportUsersInOrganizations(this.query).then(file => {
            if(file) {
                window.location.href=getDownloadLink(file.safeId, file.name);
            }
        }).finally(waitGlass());
    }

    queryUsersInOrganization=(query: TableQuery) => {
        this.query=query;   // zapamiętujemy zapytanie na potrzeby eksportu
        return store.userApi.queryUsersInOrganizations(query);
    }

    render() {
        return <>
            <Breadcrumb>
                <BreadcrumbItem to={HomeScreen.url}>{msgs.admin.menuDesktop}</BreadcrumbItem>
                <BreadcrumbItem active>{msgs.admin.menuUsersInOrganization}</BreadcrumbItem>
            </Breadcrumb>
            <PageHeader title={msgs.admin.menuUsersInOrganization}>
                {this.edit?<Button onClick={e => this.onAddUser(e)}>{msgs.gui.actionAddUser}</Button>:null}
            </PageHeader>
            <Nav
                variant="tabs"
                defaultActiveKey={this.state.tabStatus}
                onSelect={(key) => {
                    this.setState({
                        tabStatus: key,
                        statusFilter: [{ field: 'status', value: key }],
                        dataTimestamp: Date.now(),
                    });
                    replaceHistoryState(this.props.history, this.props.location, 'tab', key);
                }}
            >
                <Nav.Item><Nav.Link eventKey="New">{msgs.gui.labelUsersToAccept}</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="Active">{msgs.gui.labelUsersActive}</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="TemporaryBlocked">{msgs.gui.labelUsersTempBlocked}</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="Blocked">{msgs.gui.labelUsersBlocked}</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="Rejected">{msgs.gui.statusRejected}</Nav.Link></Nav.Item>
                {this.report?<div className="actions"><Button variant="info" onClick={() => this.exportResults()}>{msgs.gui.actionExportResults}</Button></div>:null}
            </Nav>
            <div className="tab-content">
                <DataTable
                    className="datatable-users"
                    onSelectionChange={this.state.tabStatus==="New"?this.handleSelectionChange:null}
                    columns={this.columns}
                    data={this.queryUsersInOrganization}
                    customFilters={this.state.statusFilter}
                    onRowClick={this.handleRowClick}
                    dataTimestamp={this.state.dataTimestamp}
                    historyState={"table_"+this.state.tabStatus}
                    key={this.state.tabStatus}
                    initialSortBy={this.state.tabStatus==="New"?"-registered":"-lastLogged"}
                />
                {this.renderSelectedActions()}
            </div>
        </>;
    }
}
UsersScreen.contextType=LangContext;

const AgreementsView = ({ agreements }: {
    agreements: Array<UserAgreementInfo>;
}) => {
    let res=[];
    let curGroup="";
    agreements.sort((a1, a2) => a1.order-a2.order).forEach((a: UserAgreementInfo) => {
        const group=(a.group)?getLangValue(a.group):"";
        if(curGroup!==group) {
            curGroup=group;
            if(group) res.push(<Row key={"gr_"+a.id}>
                <Col md={12} dangerouslySetInnerHTML={{ __html: group }}/>
            </Row>)
        }
        res.push(<Row key={a.id}>
            <Col md={8} className={(a.required?"text-danger":"")+(curGroup?" pl-5":"")} dangerouslySetInnerHTML={{ __html: getLangValue(a.name) }}/>
            <Col md={4} className="text-right">{
                (!a.date || a.withdraw)?<p className="text-danger">{msgs.admin.infoNoAgreement}</p>:<p className="text-success">{formatString(msgs.admin.infoAgreementAt, formatDateTime(a.date))}</p>
            }</Col>
        </Row>);
    });
    return res;
}

type EditProps = RouteChildrenProps<{ id: string }> & {

}

type EditState = {
    data: UserData|null;
    services: Array<AccessRight>|null;
    resetPassword: boolean;
    globalDicts: Dictionaries;
}

export class EditUserDialog extends React.Component<EditProps, EditState> {
    static url="/user/:id";
    /** W linku jest id użytkownika w organizacji */
    static link=(id: string) => "/user/"+id;

    static sUrl="/suser/:id";
    /** W linku jest id użytkownika w organizacji */
    static sLink=(id: string) => "/suser/"+id;

    static userRights: Array<UserRight>;

    simple: boolean;
    edit: boolean;

    constructor(props) {
        super(props);
        this.simple=this.props.match.path.includes("/suser/");

        this.edit=store.hasRight("UsersWrite");
        this.state={
            data: null,
            services: null,
            resetPassword: false,
        }
    }

    componentDidMount(): void {
        const id=this.props.match.params.id;

        if(!EditUserDialog.userRights) {
            store.userApi.getUsersRights().then(userRights => {
                EditUserDialog.userRights=userRights;
                this.forceUpdate();
            })
        }
        store.getOrderTypes().then(() => {
            this.setState({
                services: store.getOtherOrders().map((o: OrderTypeInfo) => ({
                    typeId: o.id,
                    name: o.name
                }))
            });
        })

        store.getAgreements().then(agreements => this.setState({ agreements }));
        if(this.simple) {
            store.userApi.getSimpleUser(id).then((data: UserData) => {
                this.setState({data});
            });
        } else {
            store.userApi.getUser(id).then((data: UserData) => {
                this.setState({data});
            });
        }
        store.getDictionaries().then(globalDicts => this.setState({ globalDicts }));
    }

    render() {
        if (!this.state.data || !EditUserDialog.userRights || !this.state.services || !this.state.globalDicts) return null;
        const lang=currentLang.code;
        const isNew=!this.state.data.id;
        return <Form
            readonly={!this.edit}
            initialValues={this.state.data}
            validate={UserDataValidation}
            onSubmit={async (value, helper) => {
                let { agreements, notifications, password, ...data }: UserData = value;
                if(!isNew && !this.state.resetPassword) password=null;
                let res;
                if(this.simple) {
                    res=await store.userApi.saveSimpleUser({
                        notifications: null,
                        password,
                        ...data,
                    });

                } else {
                    res=await store.userApi.saveUser({
                        notifications: notifications,
                        password,
                        ...data,
                    });
                }
                if(!Form.setError(helper, res)) {
                    this.props.history.goBack();
                    events.emit(Events.UserOrganizationChange);
                }
            }}
        >{(formik) => <Dialog
            title={isNew ? msgs.gui.titleAddUser : msgs.gui.titleEditUser}
            onAccept={this.edit?(e) => { formik.handleSubmit(e); return false; }:null}
        >
            <Tabs id="user-tabs">
                <Tab eventKey="main" title={msgs.gui.labelMainData}>
                    <Form.Row>
                        <Col md={12}>
                            <Form.Group name="name">
                                <Form.Label>{msgs.gui.labelFullName}</Form.Label>
                                <Form.Text/>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col md={6}>
                            <Form.Group name="email">
                                <Form.Label>{msgs.gui.labelEmailLogin}</Form.Label>
                                <Form.Email disabled={!isNew}/>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group name="phone">
                                <Form.Label>{msgs.gui.labelPhone}</Form.Label>
                                <Form.Phone/>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    {isNew?null:<Form.Row>
                        <Col md={6}>
                            {(this.state.resetPassword)?<Form.Group name="password">
                                <Form.Label>{msgs.gui.labelPassword}</Form.Label>
                                <Form.Password checkStrength/>
                            </Form.Group>:<Button
                                variant="link"
                                onClick={() => this.setState({ resetPassword: true })}
                            ><span className="icon gradient-circle mr-2"><span className="icon-lock"/></span> {msgs.admin.actionResetPassword}</Button>}
                        </Col>
                    </Form.Row>}
                </Tab>
                {isNew?null:<Tab eventKey="organizations" title={msgs.gui.labelOrganizations}>
                    <UserCompanySettings
                        globalDicts={this.state.globalDicts}
                        organizations={formik.values.organizations}
                        onChange={org => formik.setFieldValue("organizations", org)}
                        readonly={!this.edit}
                        userRights={EditUserDialog.userRights}
                        admin
                    />
                    <OrganizationSuggest
                        value={this.props.match.params.id}
                        onAddOrganization={(orgId) => {
                            console.log("Add organization", orgId);
                            store.userApi.getUserToOrganizationStruct(this.props.match.params.id, orgId)
                                .then((uo: UserOrganizationData) => {
                                    if(formik.values.organizations.find((i: UserOrganizationData) => i.userId===uo.userId && i.orgId===uo.orgId)) return;   // już jest

                                    formik.setFieldValue('organizations',
                                        [...formik.values.organizations, uo]
                                    )
                                })
                        }}
                    />
                </Tab>}
                {(isNew || this.simple)?null:<Tab eventKey="notifications" title={msgs.gui.labelNotifications}>
                    <Notifications
                        value={formik.values.notifications}
                        onChange={(n) => formik.setFieldValue("notifications", n)}
                        readonly={!this.edit}
                        services={this.state.services}
                    />
                </Tab>}
                {isNew?null:<Tab eventKey="agreements" title={msgs.gui.labelAgreements}>
                    <AgreementsView agreements={formik.values.agreements}/>
                </Tab>}
            </Tabs>
        </Dialog>}</Form>;
    }
}
EditUserDialog.contextType=LangContext;